/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EventTestimonialDto_jsonld_event_write } from './EventTestimonialDto_jsonld_event_write';

export type Event_EventDto_jsonld_event_write = {
    name: string | null;
    description: string | null;
    image: string | null;
    gallery?: Array<string>;
    date: string | null;
    type: Event_EventDto_jsonld_event_write.type | null;
    testimonials?: Array<EventTestimonialDto_jsonld_event_write>;
    slug: string | null;
};

export namespace Event_EventDto_jsonld_event_write {

    export enum type {
        BIRTHDAY_PARTY = 'BirthdayParty',
        PROPOSAL = 'Proposal',
        BACHELOR_PARTY = 'BachelorParty',
        CORPORATE_EVENT = 'CorporateEvent',
        FAMILY_EVENT = 'FamilyEvent',
        OTHER = 'Other',
    }


}

