/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Location_location_collection_read } from '../models/Location_location_collection_read';
import type { Location_location_read } from '../models/Location_location_read';
import type { Location_LocationDto_location_write } from '../models/Location_LocationDto_location_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationService {

    /**
     * Retrieves the collection of Location resources.
     * Retrieves the collection of Location resources.
     * @returns Location_location_read Location collection
     * @throws ApiError
     */
    public static apiAdminlocationsGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<Location_location_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/locations',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Creates a Location resource.
     * Creates a Location resource.
     * @returns Location_location_read Location resource created
     * @throws ApiError
     */
    public static apiAdminlocationsPost({
        requestBody,
    }: {
        /**
         * The new Location resource
         */
        requestBody: Location_LocationDto_location_write,
    }): CancelablePromise<Location_location_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/locations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a Location resource.
     * Retrieves a Location resource.
     * @returns Location_location_read Location resource
     * @throws ApiError
     */
    public static apiAdminlocationsIdGet({
        id,
    }: {
        /**
         * Location identifier
         */
        id: string,
    }): CancelablePromise<Location_location_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/locations/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the Location resource.
     * Replaces the Location resource.
     * @returns Location_location_read Location resource updated
     * @throws ApiError
     */
    public static apiAdminlocationsIdPut({
        id,
        requestBody,
    }: {
        /**
         * Location identifier
         */
        id: string,
        /**
         * The updated Location resource
         */
        requestBody: Location_LocationDto_location_write,
    }): CancelablePromise<Location_location_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/locations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the Location resource.
     * Removes the Location resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminlocationsIdDelete({
        id,
    }: {
        /**
         * Location identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/locations/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves the collection of Location resources.
     * Retrieves the collection of Location resources.
     * @returns Location_location_collection_read Location collection
     * @throws ApiError
     */
    public static apiClientlocationsGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<Location_location_collection_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/locations',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Retrieves a Location resource.
     * Retrieves a Location resource.
     * @returns Location_location_read Location resource
     * @throws ApiError
     */
    public static apiClientlocationsIdGet({
        id,
    }: {
        /**
         * Location identifier
         */
        id: string,
    }): CancelablePromise<Location_location_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/locations/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
