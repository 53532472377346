/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Addon_addon_read = {
    readonly id?: number;
    name?: string;
    description?: string;
    readonly price?: number;
    taxes?: Array<string>;
    minReservationTime?: number | null;
    maxReservationTime?: number | null;
    preBookingTime?: number | null;
    deletedAt?: string | null;
    isSendEmailOnBooking?: boolean;
    readonly priceType?: Addon_addon_read.priceType;
};

export namespace Addon_addon_read {

    export enum priceType {
        FIXED = 'fixed',
        HOURLY = 'hourly',
    }


}

